@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400italic,700italic,700);

body {
  background-color: white;
  padding: 50px 10px;
  font: 14px/1.5 'Noto Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #222;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  margin: 0 0 20px;
}

p,
ul,
ol,
table,
pre,
dl {
  margin: 0 0 20px;
}

h1,
h2,
h3 {
  line-height: 1.1;
}

h1 {
  font-size: 28px;
}

h2 {
  color: #393939;
}

h3,
h4,
h5,
h6 {
  color: #494949;
}

a {
  color: #39c;
  text-decoration: none;
}

a:hover {
  color: #069;
}

a small {
  font-size: 11px;
  color: #777;
  margin-top: -0.3em;
  display: block;
}

a:hover small {
  color: #777;
}

blockquote {
  border-left: 1px solid #e5e5e5;
  margin: 0;
  padding: 0 0 0 20px;
  font-style: italic;
}

code,
pre {
  font-family: Monaco, Bitstream Vera Sans Mono, Lucida Console, Terminal, Consolas, Liberation Mono, DejaVu Sans Mono,
    Courier New, monospace;
  color: #333;
  font-size: 12px;
}

code.highlighter-rouge {
  background: #f0f0f0;
}

pre {
  padding: 8px 15px;
  background: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  vertical-align: top;
  padding: 5px 10px;
  border-bottom: 1px solid #e5e5e5;
}

dt {
  color: #444;
  font-weight: 700;
}

th {
  color: #444;
}

img {
  max-width: 100%;
}

strong {
  color: #222;
  font-weight: 700;
}

small {
  font-size: 11px;
}

hr {
  border: 0;
  background: #e5e5e5;
  height: 1px;
  margin: 0 0 20px;
}
